<template>
    <div class="section">
        <page-title :title="title" class="ml-2"></page-title>
        <b-container fluid>
            <b-card>
                <spinner v-if="refreshData"></spinner>
                <div v-if="!refreshData">
                    <custom-table
                        v-if="!refreshData"
                        :tableConfig="tableConfig"
                        :fields="fields"
                        :items="items"
                        :page="page"
                        @radioButtonTableFilterChange="updateItems"
                    ></custom-table>
                </div>
            </b-card>
        </b-container>
    </div>
</template>
<script>
import CustomTable from '@/components/shared/table/CustomTable';
import { SstRepository } from '@/repositories';
import PageTitle from '@/layouts/components/PageTitle.vue';
import Spinner from '@/components/shared/element/Spinner';
import { TABLE } from '@/constants';
import { INVOICE } from '@/constants/sst/invoice';

export default {
    name: 'SstInvoicePending',
    components: {
        PageTitle,
        CustomTable,
        Spinner
    },
    data() {
        return {
            title: this.$t('sst.pages.pendingInvoice.title'),
            items: [],
            fields: [
                {
                    key: 'intervention_request_id',
                    label: this.$t('sst.pages.pendingInvoice.table.columns.id'),
                    sortable: true,
                    class: 'text-left'
                },
                {
                    key: 'sst_bill_ref',
                    label: this.$t('sst.pages.pendingInvoice.table.columns.sstBilRef'),
                    sortable: true,
                    class: 'text-left'
                },
                {
                    key: 'ttc_amount',
                    label: this.$t('sst.pages.pendingInvoice.table.columns.ttcAmount'),
                    sortable: true,
                    class: 'text-left'
                },
                {
                    key: 'sst_bill_record_date',
                    label: this.$t('sst.pages.pendingInvoice.table.columns.registeredDate'),
                    sortable: true,
                    class: 'text-left'
                },
                {
                    key: 'payment_validation_date',
                    label: this.$t('sst.pages.pendingInvoice.table.columns.validDate'),
                    sortable: true,
                    class: 'text-left'
                },
                {
                    key: 'show_details',
                    label: this.$t('sst.pages.pendingInvoice.table.columns.status'),
                    sortable: false,
                    class: 'text-center'
                }
            ],
            tableConfig: {
                sortBy: 'sst_bill_record_date',
                striped: true,
                bordered: true,
                headVariant: 'dark',
                sortDesc: true,
                displayCount: true,
                displayTotalRows: false,
                currentPage: 1,
                radioButtonsOptions: [],
                radioButtonSelectedOption: INVOICE.PENDING.RADIO_BUTTONS_KEYS.ALL
            },
            refreshData: true,
            data: [],
            page: TABLE.PAGE.SST.INVOICE.PENDING.ID
        };
    },
    created() {
        this.getData();
    },
    methods: {
        getData() {
            this.refreshData = true;
            SstRepository.getPendingInvoices()
                .then(response => {
                    this.refreshData = false;
                    this.items = response.data.data;
                    this.data = response.data.data;
                    this.items.map(element => {
                        if (element.block_date !== null) {
                            element._showDetails = true;
                        }
                    });
                })
                .catch(error => {
                    this.refreshData = false;
                    console.log(error);
                });
        },
        updateItems(radioButtonSelectedValue) {
            this.items = this.data;
            this.items = this.items.filter(element => {
                switch (radioButtonSelectedValue) {
                    case INVOICE.PENDING.RADIO_BUTTONS_KEYS.ALL:
                        return element;
                }
            });
        }
    }
};
</script>
